<template>
    <section class="login-content">
         <div class="container">
            <div class="row align-items-center justify-content-center height-self-center">
               <div class="col-lg-8">
                  <div class="card auth-card">
                     <div class="card-body p-0">
                        <div class="d-flex align-items-center auth-content">
                           <div class="col-lg-6  content-left">
                              <div class="p-3">
                                 <h2 class="mb-2 ">Iniciar Sessão</h2>
                                 <form @submit.prevent="onSubmit()">
                                    <div class="row">
                                       <div class="col-lg-12">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.email.$error ? 'is-invalid' : ''}`" 
                                                v-model="email" 
                                                type="email" 
                                                placeholder="" 
                                                @input="$v.email.$touch()" 
                                                @blur="$v.email.$touch()"
                                             >
                                             <label>Email</label>
                                             <div class="invalid-feedback">
                                                <span v-if="!$v.email.email">Email inválido</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-lg-12">
                                          <div class="form-group">
                                             <input 
                                                class="floating-input form-control" 
                                                :class="`form-control ${$v.pwd.$error ? 'is-invalid' : ''}`" 
                                                v-model="pwd"
                                                type="password" 
                                                placeholder=""
                                                @input="$v.pwd.$touch()" 
                                                @blur="$v.pwd.$touch()"
                                             >
                                             <label>Palavra-passe</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                          <div class="custom-control custom-checkbox mb-3">
                                             <input type="checkbox" class="custom-control-input" id="customCheck1">
                                             <label class="custom-control-label control-label-1 " for="customCheck1">Lembrar-me</label>
                                          </div>
                                       </div>
                                       <div class="col-lg-6">
                                        <router-link :to="{name: 'auth.recover-password'}" class="text-primary float-right">Esqueceu-se da senha?</router-link>

                                       </div>
                                    </div>
                                    <button type="submit" class="btn btn-primary">Iniciar Sessão</button>
                                    <p class="mt-3">
                                       Crie uma Conta <router-link :to="{name: 'auth.register'}" class="text-primary">Inscreva-se</router-link>
                                    </p>
                                 </form>
                              </div>
                           </div>
                           <div class="col-lg-6 content-right">
                              <div class="row">
                                 <div class="col-12">
                                    <img src="@/assets/images/logo.svg" class="img-fluid image-right" alt="">
                                 </div>
                                 <div class="col-12">
                                    <img class="w-100" src="@/assets/images/footer.png" alt="">
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
    name:'SignIn',
    mixins: [validationMixin],
    validations: {
      email:{ required , email },
      pwd: { required }
    },
    data() {
      return {
         email: 'admin@wilddouro.pt',
         pwd:'admin123!'
      }
    },
    methods:{
      onSubmit()
      {
         this.$v.$touch()
         if (!this.$v.$invalid) {
            localStorage.setItem('user',  JSON.stringify({
               email: this.email,
               pwd: this.pwd
            }))
            this.$router.push({name:'layout.dashboard1'})
         }
      }
    }
}
</script>